export const AIRDROP_URL = process.env.AIRDROP_URL || 'https://airdrop-api.lamina1.dev/'
export const AIRDROP_API_KEY = process.env.AIRDROP_API_KEY || ''
export const DISCORD_AIRDROP =
  process.env.DISCORD_AIRDROP ||
  'https://discord.com/channels/981694584474181654/1250916419370225926'
export const AIRDROP_GUIDE = process.env.AIRDROP_GUIDE || 'https://medium.com/@LAMINA1/8a727d2ef109'
export const STAKING_GUIDE = process.env.STAKING_GUIDE || 'https://medium.com/@LAMINA1/dd08e82e0259'
export const AIRDROP_APPEAL_FORM =
  process.env.AIRDROP_APPEAL_FORM ||
  'https://blocksurvey.io/airdrop-appeal-form-1PwPnYK2QIuCeUMhxFD01A?v=o'
export interface AirdropRecipient {
  AirdropID: number
  NodeID: string
  RecipientHash: string
  RewardAmount: bigint
  MerkleProof: string[]
  IsPublic: boolean
  IsDiscord: boolean
}

export type RewardKey = {
  AirdropID: number
  NodeID: string
}

export interface LinkedRewards {
  airdropID: number[]
  nodeID: string[]
}

export interface Reward {
  claimed: boolean
  revoked: boolean
  subsidyReceived: number
  linkedAddress: string | `0x${string}`
}
