import { Container, Menu, Stack, Title, useMantineTheme } from '@mantine/core'
import { useCallback, useState } from 'react'

import l1TokenBlack from '@/assets/tokens/l1TokenBlack.svg'
import l1TokenWhite from '@/assets/tokens/l1TokenWhite.svg'
import { useChainValues } from '@/hooks/useChain'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'

import DisplayUsername from '../DisplayUsername'
import IconWrapper from '../MediaWrappers/IconWrapper'
import WalletManagement from '../Wallet/WalletManagement'
import classes from './WalletButton.module.css'

const BalanceDisplay: React.FC = () => {
  const { balance } = useChainValues(l1NativeWagmiConfig.id)
  return (
    <Stack style={{ gap: '8px', flexDirection: 'row', alignItems: 'center' }}>
      <IconWrapper classes={classes.tokenLogo} iconDark={l1TokenWhite} iconLight={l1TokenBlack} />
      <Title order={4}>{Number.isNaN(Number(balance.fullValue)) ? '-' : balance.display}</Title>
    </Stack>
  )
}

interface WalletButtonProps {
  address: string
  username?: string
}

const WalletButton: React.FC<WalletButtonProps> = ({ address, username }) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const theme = useMantineTheme()

  const openWallet = useCallback(() => {
    setMenuOpened(previous => !previous)
  }, [])

  const closeMenu = useCallback(() => {
    setMenuOpened(false)
  }, [])

  return (
    address && (
      <Menu
        trigger="hover"
        openDelay={500}
        closeDelay={100000}
        opened={menuOpened}
        onClose={closeMenu}
        closeOnClickOutside
        closeOnEscape
        position="bottom-end"
      >
        <Menu.Target>
          <Container
            className={classes.walletButtonContainer}
            style={{
              border: menuOpened
                ? `1px solid ${theme.colors.splitter[0]}`
                : '1px solid transparent',
              backgroundColor: menuOpened ? theme.colors.background2[0] : 'transparent',
            }}
            onClick={openWallet}
          >
            <Stack gap="5px">
              <BalanceDisplay />
              <DisplayUsername address={address} username={username} colorless noButton />
            </Stack>
          </Container>
        </Menu.Target>
        <Menu.Dropdown className={classes.dropdown}>
          <WalletManagement closePopup={closeMenu} />
        </Menu.Dropdown>
      </Menu>
    )
  )
}

export default WalletButton
