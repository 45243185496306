import React from 'react'

import SendModal from '@/components/Modals/Wallet/SendModal'
import { useModalContext } from '@/contexts/ModalContext'

import AddToMetamaskModal from './Wallet/AddToMetamaskModal'
import ReceiveModal from './Wallet/ReceiveModal'

const ModalWrapper: React.FC = () => {
  const { modalType, closeModal, modalProps } = useModalContext()

  if (!modalType) {
    return null
  }

  // Add closeModal to modalProps to run after the modal closes
  const close = () => {
    if (modalProps?.close) {
      modalProps.close()
    }
    closeModal()
  }

  switch (modalType) {
    case 'send':
      return <SendModal close={close} />
    case 'receive':
      return <ReceiveModal close={close} />
    case 'watchTokens':
      return <AddToMetamaskModal close={close} />
    default:
      return null
  }
}

export default ModalWrapper
