/**
 * This is a file that shouldn't import anything,
 * so it can be used from another TS project (netlify-functions)
 */

import { TotalOfItems } from '../utils.schema'

export type NFTCollection = {
  id: string
  collectionType: string
  createdAt: string
  createdAtBlock: number
}

export type NftTransfer = {
  from: string
  to: string
  timestamp: string
  type: string
  operator: string
  txnHash: string
}

export type OwnedNFT = {
  id: string
  nativeId: string
  uri: string
  name: string
  symbol: string
  amount: number
  decimals: number
  metadata: any
  transfers: NftTransfer[]
  collection: NFTCollection
}

export type GetTotalNumberOfItems = {
  totalOwned: {
    agg: {
      count: number
    }
  }
  totalPublished: {
    agg: {
      count: number
    }
  }
}

export const QUERY_TOTAL_NUMBER_OF_ITEMS = `
  query GetTotalNumberOfItems($where1: balance_bool_exp!, $where2: token_bool_exp!) {
    totalOwned: balance_aggregate(where: $where1) {
      agg: aggregate {
        count
      }
    }
    totalPublished: token_aggregate(where: $where2) {
      agg: aggregate {
        count
      }
    }
  }
`

export type GetItemsQuery = TotalOfItems & {
  balance: {
    token: OwnedNFT
  }[]
}

export const QUERY_ITEMS_QUERY = `
  query GetItemsQuery($where: balance_bool_exp!, $limit: Int, $offset: Int) {
    balance(
      where: $where,
      limit: $limit
      offset: $offset,
      order_by: { updated_at_block: asc }
    ) {
      token {
        id
        nativeId: native_id
        uri
        name
        symbol
        amount
        decimals
        metadata
        transfers {
          from
          to
          timestamp
          type: transfer_type
          operator
        }
        collection {
          id
          collectionType: collection_type
          createdAt: created_at
          createdAtBlock: created_at_block
        }
      }
    }
    total: balance_aggregate(where: $where) {
      agg: aggregate {
        count
      }
    }
  }
`

export const SUBSCRIBE_ITEMS_QUERY = `
  subscription GetItemsQuery($where: balance_bool_exp!) {
    balance(where: $where, order_by: { updated_at_block: asc }) {
      token {
        id
        nativeId: native_id
        uri
        name
        symbol
        amount
        decimals
        metadata
        transfers {
          from
          to
          timestamp
          type: transfer_type
          operator
        }
        collection {
          id
          collectionType: collection_type
          createdAt: created_at
          createdAtBlock: created_at_block
        }
      }
    }
  }
`

export type GetItemsQueryByCreator = TotalOfItems & {
  token: OwnedNFT[]
}

export const QUERY_ITEMS_QUERY_BY_CREATOR = `
  query GetItemsQueryByCreator($where: token_bool_exp!, $limit: Int, $offset: Int) {
    token(
      where: $where,
      limit: $limit
      offset: $offset,
      order_by: {native_id: asc},
    ) {
      id
      nativeId: native_id
      uri
      name
      symbol
      amount
      decimals
      metadata
      transfers (
        order_by: { timestamp: asc }
      ) {
        from
        to
        timestamp
        type: transfer_type
        operator
      }
      collection {
        id
        collectionType: collection_type
        createdAt: created_at
        createdAtBlock: created_at_block
      }
    }
    total: token_aggregate(where: $where) {
      agg: aggregate {
        count
      }
    }
  }
`

export const SUBSCRIBE_ITEMS_QUERY_BY_CREATOR = `
  subscription GetItemsQueryByCreator($where: token_bool_exp!) {
    token(where: $where) {
      id
      nativeId: native_id
      uri
      name
      symbol
      amount
      decimals
      metadata
      transfers {
        from
        to
        timestamp
        type: transfer_type
        operator
      }
      collection {
        id
        collectionType: collection_type
        createdAt: created_at
        createdAtBlock: created_at_block
      }

    }
  }
`

export type GetCollectionItemsQuery = TotalOfItems & {
  token: OwnedNFT[]
}

export const QUERY_COLLECTION_ITEMS_QUERY = `
  query GetCollectionItemsQuery($where: token_bool_exp!, $limit: Int, $offset: Int) {
    token(
      where: $where,
      limit: $limit
      offset: $offset,
    ) {
      id
      nativeId: native_id
      uri
      name
      symbol
      amount
      decimals
      metadata
      transfers (
        order_by: { timestamp: asc }
      ) {
        from
        to
        timestamp
        type: transfer_type
        operator
      }
      collection {
        id
        collectionType: collection_type
        createdAt: created_at
        createdAtBlock: created_at_block
      }
    }
    total: token_aggregate(where: $where) {
      agg: aggregate {
        count
      }
    }
  }
`

export type GetItemOwner = {
  balance: {
    account: string
  }[]
}

export const QUERY_ITEM_OWNER = `
  query GetItemOwner($id: String!) {
    balance(where: {token: {id: {_eq: $id}}}) {
      account
    }
  }
`

export type GetNftById = {
  token: OwnedNFT
}

export type GetNftsByIds = {
  tokens: OwnedNFT[]
}

export const GET_NFT_BY_ID_QUERY = `
  query GetNFTByTokenId($id: String!) {
    token: token_by_pk(id: $id) {
      id
      nativeId: native_id
      uri
      name
      symbol
      amount
      decimals
      metadata
      transfers {
        from
        to
        timestamp
        type: transfer_type
        txnHash: txn_hash
      }
      collection {
        id
        collectionType: collection_type
        createdAt: created_at
        createdAtBlock: created_at_block
      }
    }
  }
`

export const QUERY_NFTS_BY_IDS_QUERY = `
  query GetNftsByIds($ids: [String!]!) {
    tokens: token(where: {id: {_in: $ids}}) {
      id
      nativeId: native_id
      uri
      name
      symbol
      amount
      decimals
      metadata
      transfers {
        from
        to
        timestamp
        type: transfer_type
        txnHash: txn_hash
      }
      collection {
        id
        collectionType: collection_type
        createdAt: created_at
        createdAtBlock: created_at_block
      }
    }
  }
`

export type SubTotalOwnedItems = {
  totalOwned: {
    agg: {
      count: number
    }
  }
  totalPublished: {
    agg: {
      count: number
    }
  }
}

export const SUBSCRIBE_TOTAL_OWNED_QUERY = `
  subscription TotalOwned($account: String!) {
    totalOwned: balance_aggregate(where: {
      _and: [
        { account: { _eq: $account } },
        { token: { collection: { collection_type: { _neq: "ERC20" } } } },
      ]
    }) {
      agg: aggregate {
        count
      }
    }
  }
`
