import { useCallback } from 'react'
import { namehash, normalize } from 'viem/ens'

import {
  CONTRACT_CONTROLLER_ADDRESS,
  CONTRACT_REGISTRY_ADDRESS,
  CONTRACT_RESOLVER_ADDRESS,
} from '@/constants/blockchain'
import ENS from '@/contracts/ENS'
import L1Controller from '@/contracts/L1NSController'
import L1Resolver from '@/contracts/PublicResolver'
import { l1IdSubnetWagmiConfig } from '@/plugins/auth/config'

import { useChainFunctions } from './useChain'

const useL1NS = () => {
  const { readContract } = useChainFunctions(l1IdSubnetWagmiConfig)

  // Async functions
  const getL1nsName = useCallback(async (address: string): Promise<string> => {
    try {
      const name = normalize(`${String(address).substring(2)}.addr.reverse`)
      const node = namehash(name)
      const data = await readContract({
        address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
        abi: L1Resolver,
        functionName: 'name',
        args: [node],
      })
      return (data as string).split('.', 1)[0] ?? ''
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error getting L1NS Username from address ${address}.\n
        Error Message: ${error.message}`)
    }
  }, [])

  const getL1nsAddress = useCallback(async (handle: string): Promise<string> => {
    try {
      const name = normalize(`${handle}.l1`)
      const node = namehash(name)
      const data = await readContract({
        address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
        abi: L1Resolver,
        functionName: 'addr',
        args: [node],
      })
      return data as string
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error getting L1NS Address from username ${handle}.\n
        Error Message: ${error.message}`)
    }
  }, [])

  const getL1nsNickname = useCallback(async (handle: string): Promise<string> => {
    const name = normalize(`${handle}.l1`)
    const node = namehash(name)
    try {
      const data = await readContract({
        address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
        abi: L1Resolver,
        functionName: 'text',
        args: [node, 'displayname'],
      })
      return data as string
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error getting L1NS Nickname from username: ${error.message}`)
    }
  }, [])

  const getL1nsProfile = useCallback(async (address: string): Promise<string> => {
    const name = normalize(`${String(address).substring(2)}.addr.reverse`)
    const node = namehash(name)
    try {
      const data = await readContract({
        address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
        abi: L1Resolver,
        functionName: 'text',
        args: [node, 'profile'],
      })
      return data as string
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error getting L1NS Profile from username: ${error.message}`)
    }
  }, [])

  const getL1nsNodeOwner = useCallback(async (node: string): Promise<string> => {
    try {
      const data = await readContract({
        address: CONTRACT_REGISTRY_ADDRESS as `0x${string}`,
        abi: ENS,
        functionName: 'owner',
        args: [node as `0x${string}`],
      })
      return data as string
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error getting L1NS Node Owner from node: ${error.message}`)
    }
  }, [])

  const confirmHandleAvailability = useCallback(async (handle: string): Promise<boolean> => {
    try {
      const data = await readContract({
        address: CONTRACT_CONTROLLER_ADDRESS as `0x${string}`,
        abi: L1Controller,
        functionName: 'available',
        args: [handle],
      })
      return data as boolean
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error confirming L1NS handle availability: ${error.message}`)
    }
  }, [])

  const getDifficulty = useCallback(async (): Promise<string> => {
    try {
      const data = await readContract({
        address: CONTRACT_CONTROLLER_ADDRESS as `0x${string}`,
        abi: L1Controller,
        functionName: 'difficulty',
      })
      return data as string
    } catch (error: any) {
      console.error('Error:', error)
      throw new Error(`Error getting L1NS free username PoW difficulty: ${error.message}`)
    }
  }, [])

  return {
    getL1nsName,
    getL1nsAddress,
    getL1nsNickname,
    getL1nsProfile,
    getL1nsNodeOwner,
    confirmHandleAvailability,
    getDifficulty,
  }
}

export default useL1NS
