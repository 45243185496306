import { Button, ButtonProps } from '@mantine/core'
import React from 'react'

import classes from './RootButton.module.css'

export interface RootButtonProps extends ButtonProps {
  onClick?: (e?: any) => void
  secondary?: boolean
  style1?: boolean
  expand?: boolean
}

const RootButton: React.FC<RootButtonProps> = ({
  onClick,
  secondary = false,
  style1 = true,
  expand = false,
  style,
  ...props
}) => (
  <Button
    style={{
      flexGrow: expand ? 1 : 0,
      height: style1 ? '48px' : '36px',
      fontFamily: 'monument-grotesk-black',
      ...style,
    }}
    className={secondary ? classes.secondaryButton : classes.primaryButton}
    {...props}
    onClick={onClick}
  />
)

export default RootButton
