import { Stack, Text } from '@mantine/core'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import RootButton from '../Buttons/RootButton'
import ErrorMessage from '../ErrorMessage'

interface DiscordShareModalProps {
  shareMessage: string
  successMessage: string
  errorMessage: string | JSX.Element
  webhook: string
  close: () => void
}

const DiscordShareModal: React.FC<DiscordShareModalProps> = ({
  shareMessage,
  successMessage,
  errorMessage,
  webhook,
  close,
}) => {
  const { t } = useTranslation()
  const [discordShareStatus, setDiscordShareStatus] = useState<'' | 'success' | 'error'>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const channelLink = useRef<string>('')

  const shareDiscord = useCallback(() => {
    setLoading(true)
    fetch(webhook, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: shareMessage,
      }),
    })
      .then(response => {
        response.json()
        setDiscordShareStatus('success')
        // Fetch discord channel url from webhook env variable
        fetch(process.env.WEBHOOK_DISCORD_PROFILE || '')
          .then(res => res.json())
          .then(data => {
            channelLink.current = `https://discord.com/channels/${data.guild_id}/${data.channel_id}`
            setLoading(false)
          })
      })
      .catch((err: any) => {
        console.error('Error:', err)
        setDiscordShareStatus('error')
        setError(err.message)
        setLoading(false)
      })
  }, [webhook, shareMessage, setDiscordShareStatus, setError, setLoading, channelLink])

  return (
    <>
      {discordShareStatus === 'error' && (
        <Stack style={{ gap: '20px' }}>
          <ErrorMessage message={errorMessage} details={error} />
        </Stack>
      )}
      {discordShareStatus === 'success' && (
        <Stack style={{ gap: '20px' }}>
          <Text style={{ fontSize: '18px', marginRight: 1, wordWrap: 'break-word' }}>
            {successMessage}
          </Text>
          <Link style={{ wordWrap: 'break-word' }} to={channelLink.current} target="_blank">
            {channelLink.current}
          </Link>
        </Stack>
      )}

      {!discordShareStatus && (
        <Stack style={{ gap: '20px' }}>
          <Text style={{ fontSize: '18px', marginRight: 1, wordWrap: 'break-word' }}>
            {t('components.items.sharedDiscordCta', 'Share the following message on Discord:')}
          </Text>
          <Text style={{ fontSize: '18px', marginRight: 1, wordWrap: 'break-word' }}>
            {shareMessage}
          </Text>

          <Stack style={{ gap: '8px', flexDirection: 'row' }}>
            <RootButton secondary onClick={close} style1={false} expand>
              {t('buttons.cancel', 'Cancel')}
            </RootButton>
            <RootButton disabled={loading} style1={false} onClick={shareDiscord} expand>
              {loading ? t('buttons.sharing', 'Sharing...') : t('buttons.share', 'Share')}
            </RootButton>
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default DiscordShareModal
