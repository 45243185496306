import { Divider, Stack, Text, Title, useMantineTheme } from '@mantine/core'

import classes from './PageSelector.module.css'
import TooltipText from './TooltipText'

interface PageSelectorProps {
  count?: number | string
  bold?: boolean
  icon?: React.ReactNode
  iconOnly?: boolean
  fontSize?: string
  text: string | React.ReactNode
  onClick: () => void
  active: boolean
  hover?: boolean
  deselectedColorText?: string
  tooltipLabel?: string
}

const PageSelector: React.FC<PageSelectorProps> = ({
  count = undefined,
  bold = false,
  icon = undefined,
  iconOnly = false,
  fontSize = '',
  deselectedColorText = '',
  hover = false,
  text,
  onClick,
  active,
  tooltipLabel,
}) => {
  const theme = useMantineTheme()

  const labelStyle = {
    color: active ? theme.colors.title[0] : deselectedColorText || theme.colors.paragraph[0],
    fontSize: fontSize || '',
  }
  const preLabel = bold ? (
    <Title order={4} style={labelStyle}>
      {text}
    </Title>
  ) : (
    <Text style={labelStyle}>{text}</Text>
  )

  const label = tooltipLabel ? (
    <TooltipText text={text} tooltip={tooltipLabel} textStyle={labelStyle} />
  ) : (
    preLabel
  )

  return (
    <Stack
      style={{
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
      }}
      className={hover ? classes.selector : undefined}
      onClick={onClick}
    >
      {count && (
        <Title
          order={4}
          style={{ color: active ? theme.colors.title[0] : theme.colors.paragraph[0] }}
        >
          {count}
        </Title>
      )}
      <Stack
        style={{
          flexDirection: 'row',
          alignItems:
            // if text is a react node
            typeof text === 'object' ? 'center' : 'baseline',
          gap: 10,
        }}
      >
        {icon && icon}
        {!iconOnly && (
          <Stack style={{ gap: '1px' }}>
            {label}
            {active && (
              <Divider style={{ borderTopColor: theme.colors.l1Primary[0], borderTopWidth: 2 }} />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default PageSelector
