import { Center } from '@mantine/core'
import * as Sentry from '@sentry/react'
import React, { useEffect, useMemo } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'

import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'
import useConst from '@/hooks/useConst'
import Analytics from '@/plugins/Analytics'
import { authenticatedDynImport } from '@/plugins/auth/RouteRedirectors/authenticated'
import OwnProfileRedirector from '@/plugins/auth/RouteRedirectors/OwnProfileRedirector'
import RootRedirector from '@/plugins/auth/RouteRedirectors/RootRedirector'

import Header from './Header/Header'
import Loading from './Loading'
import NotFound from './NotFound'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const AppFrame: React.FC = () => {
  // Chain Features
  const { isLoading: isLoadingChainFeatures, hasFeature } = useChainFeaturesContext()
  const hasAirdrop = useMemo(() => hasFeature('airdrop'), [hasFeature])
  const hasGovernance = useMemo(() => hasFeature('governance'), [hasFeature])

  const lazyRoutes = useConst({
    HelpCenter: () => import('./HelpCenter/HelpCenter'),
    ItemsPage: () => import('./Items/ItemsPage'),
    CollectionPage: () => import('./Items/CollectionPage'),
    Username: () => authenticatedDynImport(import('./Identity/Username/Username')),
    CreateItem: () => authenticatedDynImport(import('./Studio/CreateItem')),
    HomePage: () => import('./Home/HomePage'),
    Wallet: () => authenticatedDynImport(import('./Wallet/Wallet')),
    Profile: () => import('./Profile/Profile'),
    DisplayItems: () => authenticatedDynImport(import('./Items/DisplayItems')),
    Governance: () => import('./Governance/Governance'),
    Airdrop: () => authenticatedDynImport(import('./Airdrop/Airdrop')),
    Activity: () => authenticatedDynImport(import('./Activity/Activity')),
    Studio: () => import('./Studio/Studio'),
    TemplatePage: () => import('./Studio/Templates/TemplatePage'),
    // SpacesPage: () => import('./Discover/SpacesPage'),
    UserSettings: () => authenticatedDynImport(import('./Settings/UserSettings')),
  })

  useEffect(() => {
    // preload other routes after the page has loaded
    const preloadLazyRoutes = async () => {
      await Promise.allSettled(
        (Object.keys(lazyRoutes) as (keyof typeof lazyRoutes)[]).map(key =>
          lazyRoutes[key]().catch(console.error)
        )
      )
    }
    window.addEventListener('load', preloadLazyRoutes)
    return () => window.removeEventListener('load', preloadLazyRoutes)
  }, [lazyRoutes])

  const router = useMemo(() => {
    if (isLoadingChainFeatures) {
      return createBrowserRouter([
        {
          path: '*',
          element: (
            <Center style={{ height: '100vh', width: '100vw' }}>
              <Loading />
            </Center>
          ),
        },
      ])
    }

    const routesObj: RouteObject[] = createRoutesFromElements(
      <Route path="/" Component={Analytics}>
        <Route path="/" element={<RootRedirector />} />
        <Route path="/loggedIn/*" element={<RootRedirector />} />
        <Route path="/profile" element={<OwnProfileRedirector />} />

        <Route path="/freeusername" lazy={lazyRoutes.Username} />
        <Route path="/premiumusername" lazy={lazyRoutes.Username} />
        {/* <Route path="/spaces/:id" lazy={lazyRoutes.SpacesPage} /> */}
        <Route path="/studio/createitem" lazy={lazyRoutes.CreateItem} />
        {hasAirdrop && <Route path="/airdrop" lazy={lazyRoutes.Airdrop} />}
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Header />}>
          <Route path="/home" lazy={lazyRoutes.HomePage} />
          <Route path="/wallet" lazy={lazyRoutes.Wallet} />
          {hasGovernance && <Route path="/governance" lazy={lazyRoutes.Governance} />}
          <Route path="/profile/:id" lazy={lazyRoutes.Profile} />
          <Route path="/items" lazy={lazyRoutes.DisplayItems} />
          <Route path="/item/:id" lazy={lazyRoutes.ItemsPage} />
          <Route path="/collection/:id" lazy={lazyRoutes.CollectionPage} />
          <Route path="/activity" lazy={lazyRoutes.Activity} />
          <Route path="/studio" lazy={lazyRoutes.Studio} />
          <Route path="/studio/templates/:id" lazy={lazyRoutes.TemplatePage} />
          <Route path="/usersettings" lazy={lazyRoutes.UserSettings} />
          <Route path="/helpcenter" lazy={lazyRoutes.HelpCenter} />
          <Route path="/helpcenter/:tabId" lazy={lazyRoutes.HelpCenter} />
        </Route>
      </Route>
    )

    return sentryCreateBrowserRouter(routesObj)
  }, [lazyRoutes, isLoadingChainFeatures, hasAirdrop, hasGovernance])

  return <RouterProvider router={router} />
}

export default AppFrame
