import { gql } from '@apollo/client'

import {
  GET_NFT_BY_ID_QUERY,
  QUERY_COLLECTION_ITEMS_QUERY,
  QUERY_ITEM_OWNER,
  QUERY_ITEMS_QUERY,
  QUERY_ITEMS_QUERY_BY_CREATOR,
  QUERY_NFTS_BY_IDS_QUERY,
  QUERY_TOTAL_NUMBER_OF_ITEMS,
  SUBSCRIBE_ITEMS_QUERY,
  SUBSCRIBE_ITEMS_QUERY_BY_CREATOR,
  SUBSCRIBE_TOTAL_OWNED_QUERY,
} from './minimal'

export * from './minimal'

export const QUERY_GET_ITEMS = gql([QUERY_ITEMS_QUERY])

export const QUERY_GET_ITEMS_BY_CREATOR = gql([QUERY_ITEMS_QUERY_BY_CREATOR])

export const QUERY_GET_TOTAL_NUMBER_OF_ITEMS = gql([QUERY_TOTAL_NUMBER_OF_ITEMS])

export const GET_ITEMS = gql([SUBSCRIBE_ITEMS_QUERY])

export const GET_ITEMS_BY_CREATOR = gql([SUBSCRIBE_ITEMS_QUERY_BY_CREATOR])

export const GET_NFT_BY_ID = gql([GET_NFT_BY_ID_QUERY])

export const GET_NFTS_BY_IDS = gql([QUERY_NFTS_BY_IDS_QUERY])

export const GET_ITEM_OWNER = gql([QUERY_ITEM_OWNER])

export const SUB_TOTAL_OWNED_ITEMS = gql([SUBSCRIBE_TOTAL_OWNED_QUERY])

export const QUERY_GET_COLLECTION_ITEMS = gql([QUERY_COLLECTION_ITEMS_QUERY])
