import { ActionIcon, ActionIconProps, Menu, Stack, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconDotsVertical } from '@tabler/icons-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCopyClipboard from '@/hooks/useCopyToClipboard'

import { callbackCopyMessage } from './Buttons/CopyButton'
import DiscordShareModal from './Modals/DiscordShareModal'
import ModalSkeleton from './Modals/ModalSkeleton'
import classes from './OptionsMenu.module.css'

export interface DiscordSharing {
  shareMessage: string
  successMessage: string
  errorMessage: JSX.Element | string
  channelWebhook: string
}

interface OptionsMenuProps extends ActionIconProps {
  children?: React.ReactNode
  contrastDarkBackground?: boolean
  contrastLightBackground?: boolean
  copyLink?: string
  twitterSharelink?: string
  discordSharing?: DiscordSharing
  explorerLink?: string
}

const OptionsMenu: React.FC<OptionsMenuProps> = ({
  children,
  contrastDarkBackground = true,
  contrastLightBackground = false,
  copyLink = '',
  twitterSharelink = '',
  discordSharing,
  explorerLink = '',
  style,
}) => {
  const [hover, setHover] = useState<boolean>(false)
  const theme = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)
  const [, staticCopy] = useCopyClipboard()
  const { t } = useTranslation()

  return (
    <>
      <Menu withArrow shadow="md" arrowSize={22} zIndex={100}>
        <Menu.Target>
          <ActionIcon
            className={classes.actionIcon}
            style={{ ...style }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <IconDotsVertical
              style={{
                rotate: '90deg',
                color: hover
                  ? theme.colors.background[0]
                  : contrastDarkBackground
                    ? 'white'
                    : contrastLightBackground
                      ? 'black'
                      : theme.colors.title[0],
              }}
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown className={classes.dropdown}>
          <Stack className={classes.menuItems}>
            {copyLink && (
              <a
                onClick={() => staticCopy(copyLink, classes, theme, callbackCopyMessage(t))}
                className={classes.options}
              >
                {t('components.optionsMenu.copyLink', 'Copy Link')}
              </a>
            )}
            {twitterSharelink && (
              <a
                onClick={() => {
                  window.open(twitterSharelink, '_target')
                }}
                className={classes.options}
              >
                {t('components.optionsMenu.shareX', 'Share to X')}
              </a>
            )}
            {discordSharing && (
              <a onClick={open} className={classes.options}>
                {t('components.optionsMenu.shareDiscord', 'Share to Discord')}
              </a>
            )}
            {explorerLink && (
              <a
                onClick={() => {
                  window.open(explorerLink, '_blank')
                }}
                className={classes.options}
              >
                {t('components.optionsMenu.findExplorer', 'Find in Explorer')}
              </a>
            )}
            {children}
          </Stack>
        </Menu.Dropdown>
      </Menu>
      <ModalSkeleton
        opened={opened}
        onClose={close}
        title={t('components.optionsMenu.modal.shareDiscord', 'Share to Discord')}
      >
        <DiscordShareModal
          shareMessage={discordSharing?.shareMessage || ''}
          webhook={process.env.WEBHOOK_DISCORD_PROFILE || ''}
          errorMessage={discordSharing?.errorMessage || ''}
          close={close}
          successMessage={discordSharing?.successMessage || ''}
        />
      </ModalSkeleton>
    </>
  )
}
export default OptionsMenu
