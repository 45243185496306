import { ActionIcon, Grid, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { IconBrandDiscord, IconBrandTelegram, IconBrandX } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import iconicBlack from '@/assets/icons/iconic_black.svg'
import iconicWhite from '@/assets/icons/iconic_white.svg'

import classes from './Footer.module.css'
import IconWrapper from './MediaWrappers/IconWrapper'

// Regex for /item/:id pages (optional trailing slash):
const itemPageRegex = /^\/item\/[^/]+\/?$/

// Regex for both /items AND /item/:id pages (optional trailing slash):
// const itemPageRegex = /^\/item(?:\/[^/]+|s)\/?$/

const Socials: React.FC = () => {
  const theme = useMantineTheme()

  return (
    <Stack className={classes.footer}>
      {/* Discord */}
      <Link to="https://discord.com/invite/lamina1" target="_blank">
        <ActionIcon className={classes.socialIcon}>
          <IconBrandDiscord size="32px" color={theme.colors.background[0]} />
        </ActionIcon>
      </Link>
      {/* Lamina1 */}
      <Link to="https://about.lamina1.com/" target="_blank">
        <ActionIcon className={classes.socialIcon}>
          <IconWrapper
            classes={classes.iconicLogo}
            iconDark={iconicBlack}
            iconLight={iconicWhite}
          />
        </ActionIcon>
      </Link>
      {/* Twitter */}
      <Link to="https://twitter.com/Lamina1official" target="_blank">
        <ActionIcon className={classes.socialIcon}>
          <IconBrandX size="32px" color={theme.colors.background[0]} />
        </ActionIcon>
      </Link>
      <Link to="https://t.me/Lamina1Official" target="_blank">
        <ActionIcon className={classes.socialIcon}>
          <IconBrandTelegram size="32px" color={theme.colors.background[0]} />
        </ActionIcon>
      </Link>
    </Stack>
  )
}

const TermsPolicy: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Stack style={{ width: 'fit-content', height: 'fit-content', alignItems: 'end', gap: '0px' }}>
      <Text className={classes.text}>
        {t('components.footer.rights', '© 2024 Open Metaverse Limited. All rights reserved.')}
      </Text>
      <Trans i18nKey="components.footer.termsAndPolicy" transWrapTextNodes>
        <Text className={classes.text}>
          By continuing, you agree to our{' '}
          <a
            href="https://about.lamina1.com/terms-and-conditions---open-metaverse-limited"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://about.lamina1.com/privacy-policy---open-metaverse-limited"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Text>
      </Trans>
    </Stack>
  )
}

const Footer: React.FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const isItemPage = useMemo(() => !!pathname.match(itemPageRegex), [pathname])

  const handleLamina1Click = useCallback(() => {
    window.open('https://about.lamina1.com/', '_blank')
  }, [])

  const handleGithubClick = useCallback(() => {
    window.open('https://github.com/lamina1', '_blank')
  }, [])

  const handleUsersClick = useCallback(() => {
    window.open('https://docs.lamina1.com/', '_blank')
  }, [])

  const handleHelpClick = useCallback(() => {
    window.open('https://lamina1.com/helpcenter/start', '_blank')
  }, [])

  return (
    <>
      {!isItemPage && (
        <Grid className={classes.grid} justify="space-between" align="center">
          <Grid style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Grid.Col span="content">
              <Title order={5} className={classes.titleClickable} onClick={handleLamina1Click}>
                {(t('components.footer.l1', 'About Lamina1') as string).toUpperCase()}
              </Title>
            </Grid.Col>
            <Grid.Col span="content">
              <Title order={5} className={classes.titleClickable} onClick={handleGithubClick}>
                {(t('components.footer.git', 'Github') as string).toUpperCase()}
              </Title>
            </Grid.Col>
            <Grid.Col span="content">
              <Title order={5} className={classes.titleClickable} onClick={handleUsersClick}>
                {(t('components.footer.users', 'Users & Developers guide') as string).toUpperCase()}
              </Title>
            </Grid.Col>
            <Grid.Col span="content">
              <Title order={5} className={classes.titleClickable} onClick={handleHelpClick}>
                {(t('components.footer.help', 'Help Center') as string).toUpperCase()}
              </Title>
            </Grid.Col>
          </Grid>
          <Grid.Col span="content">
            <Socials />
          </Grid.Col>
          <Grid.Col span="content" style={{ alignItems: 'end' }}>
            <TermsPolicy />
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

export default Footer
